export const YOUTUBE_VIDEO_IDS = [
    {
        date: Date.now(),
        iFrameSrc: 'https://www.youtube.com/embed/FTFaQWZBqQ8?si=gkNGw9dRn8H9C1F1',

    },
    {
        date: Date.now(),
        iFrameSrc: 'https://www.youtube.com/embed/FTFaQWZBqQ8?si=gkNGw9dRn8H9C1F1',
    },
    {
        date: Date.now(),
        iFrameSrc: 'https://www.youtube.com/embed/FTFaQWZBqQ8?si=gkNGw9dRn8H9C1F1',
    },
    {
        date: Date.now(),
        iFrameSrc: 'https://www.youtube.com/embed/FTFaQWZBqQ8?si=gkNGw9dRn8H9C1F1',
    },
    {
        date: Date.now(),
        iFrameSrc: 'https://www.youtube.com/embed/FTFaQWZBqQ8?si=gkNGw9dRn8H9C1F1',
    },

]