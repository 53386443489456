import React from 'react'

function OwnerForm() {
    return (
        <div>
            Owner Form
        </div>
    )
}

export default OwnerForm
