import React from 'react'

function DoctorForm() {
    return (
        <div>
            Doctor Form
        </div>
    )
}

export default DoctorForm
